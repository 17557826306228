import React from "react";
import { Link } from "gatsby";
// import { Helmet } from "react-helmet";

// import Layout from "../components/layout"
// import SEO from "../components/seo"
// import VoucherAd from "../components/voucherAd";§
// import BackButton from "../components/backButton"
import SEO from "../components/seo";
import TopBar from "../components/Layout/TopBar";
import Navbar from "../components/Layout/Navbar";
import Footer from "../components/Layout/Footer";
import Navigation from "../components/Layout/Navigation";
import DesktopMenu from "../components/Layout/Navigation/DesktopMenu";
import MenuControls from "../components/Layout/Navigation/MenuControls";
import MobileNavigation from "../components/Layout/Navigation/MobileNavigation";

const addExternalScript = url => {
  const script = document.createElement('script');
  script.src = url;
  script.async=true;
  document.body.appendChild(script);
};
export const onClientEntry = () => {
    window.addEventListener('load', 
  function() { 
    addExternalScript("https://files.qualifio.com/kit/qualp.2.min.js");
  }, false);
//   window.onload = () => {
//     addExternalScript("https://files.qualifio.com/kit/qualp.2.min.js")

//   }
}
// useEffect(()=>{
// addExternalScript("https://mapa.ecommerce.poczta-polska.pl/widget/scripts/ppwidget.js",window.PPWidgetApp.toggleMap())
// },[])
// import "../scss/main.scss";

const GameTest = () => (
  // <Layout>
  <div>
    {/* <Seo></Seo> */}
      <TopBar></TopBar>
      <Navigation>
        <MenuControls />
        <MobileNavigation></MobileNavigation>
      </Navigation>
      <DesktopMenu />
      <div className="container">
      <div className="mx-auto py-12">
      <h1 className="font-sans font-bold text-2xl text-primary">Play Winalot® Catcher</h1>
        <div className="flex">
          <iframe src="https://purinaemena.qualifioapp.com/20/C924E0DE-57E3-41E8-B5F9-936AC2780E75/v1.cfm?id=C924E0DE-57E3-41E8-B5F9-936AC2780E75" width="1200" height="865" id="qualifio909616" class="qualifio_iframe_tag"></iframe>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>

  // {/* // </Layout> */}
);

export default GameTest;
